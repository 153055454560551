<template lang="pug">
.session-tracker
  //- span {{status}}
  //- = " "
  //- span(v-if="status == 'unknown'") ({{timeRemaining}})
</template>

<script>
const now = () => (new Date).getTime();

export default {
  props: {
    delay: {type: Number, default: () => 300},
    unknownDelay: {type: Number, default: () => 30},
  },

  data(){
    return {
      status: null,

      expirationTime: null,
      timeRemaining: null,

      intervalID: null,

      // timeRemainingIntervalID: null,
    }
  },

  mounted(){ this.touch(); },
  beforeDestroy(){ this.clear(); },

  watch: {
    status(val){
      switch(val){
        case "absent": this.$emit("expire"); break;
        case "unknown": this.$emit("sleepy"); break;
      }

      this.$emit("update:status", val)
    }
  },

  computed: {
    // prettyTimeRemaining(){
    //   let {timeRemaining} = this
    //   let db = Math.log10(timeRemaining)
    //   db = Math.floor(db*10)/10
    //
    //   return `${db} (${timeRemaining})`
    // },

  },

  methods: {
    computeStatus(timeRemaining){
      let {unknownDelay} = this

      if(timeRemaining <= 0){
        return "absent"
      }else if(timeRemaining <= unknownDelay){
        return "unknown"
      }else{
        return "present"
      }
    },

    clear(){
      clearInterval(this.intervalID);
      this.intervalID = null;
    },

    touch(){
      this.expirationTime = now() + this.delay*1000;
      this.intervalID = setInterval(this.onInterval, 100)
    },

    onInterval(){
      let ms = this.expirationTime - now();
      let timeRemaining = Math.floor(ms/1000);
      this.timeRemaining = timeRemaining
      this.status = this.computeStatus(this.timeRemaining)

      if(timeRemaining <= 0){ this.onExpire() }
    },

    onExpire(){
      this.clear();
    },
  }
}
</script>
