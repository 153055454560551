<template lang="pug">
.book-slideshow.glider-contain
  .records(ref="glider", @glider-slide-visible="onGliderSlideVisible")
    .slide.empty

    template(v-for="(book, i) in records", :i="i")
      book-list-item.slide(v-bind="book")

    .slide.empty

  button.glider-prev(aria-label="Previous") &#x25c1;
  button.glider-next(aria-label="Next") ▷
</template>

<script>
import BookListItem from "./BookListItem.vue"
import Glider from "glider-js";
import "glider-js/glider.min.css";
import { throttle } from "lodash";

export default {
  props: {
    records: Array,
    active: Boolean,
    slidesToShow: {type: Number, default: () => 3}
  },

  mounted() {
    this.mountGlider();
  },

  components: {
    BookListItem
  },

  data() {
    return {
      glider: {},
      gliderEl: null,
    };
  },

  methods: {
    bookPath(i) {
      return `/books/${i}`;
    },

    mountGlider() {
      // console.log("mount glider");

      let slideshowScroll = { ...this.$store.state.slideshowScroll };
      // console.log({ scrollTo: slideshowScroll });

      let glider = this.buildGlider();
      this.glider = glider;
      this.gliderEl = glider.ele;

      this.$nextTick(function () {
        if (slideshowScroll?.i) this.scrollTo(slideshowScroll);
      });
    },

    buildGlider() {
      return new Glider(this.$refs.glider, {
        slidesToShow: this.slidesToShow,
        draggable: true,
        scrollLock: true,
        rewind: true,

        arrows: {
          prev: ".glider-prev",
          next: ".glider-next",
        },
      });
    },

    // Gets called for each visible slide (more than once)
    onGliderSlideVisible({ target: element }) {
      let activeSlide = this.$el.querySelector(".glider-track .center");

      if (!activeSlide) return;
      let i = +activeSlide.dataset.gslide;

      let { scrollLeft } = element;

      this.updateActiveSlide({ i, scrollLeft });
    },

    updateActiveSlide: throttle(
      function (obj) {
        // console.log({ setSlideshowScroll: obj });
        this.$store.commit("setSlideshowScroll", obj);
      },
      200,
      { leading: false }
    ),

    scrollTo({ i, scrollLeft }) {
      this.gliderEl.scrollLeft = scrollLeft;
      this.glider.scrollItem(i - 1);
    },

    scrollItem(i){
      return this.glider.scrollItem(i);
    },

    scrollNext(){
      return this.glider.arrows.next._func()
    },

    // scrollLeftTo(x) {
    //   this.$refs.glider.scrollLeft = x;
    // },
  },
};
</script>

<style lang="sass">
.book-slideshow, .glider-track
  min-height: 40vh
  // max-height: 50vh
  overflow: hidden

.glider-contain
  margin: 0 var(--view-antipadding, -2em)
  width: auto


.glider
  scrollbar-width: none
  margin: 0 1em


.glider-next, .glider-prev
  background: #0004
  color: white

  font-weight: bold
  font-size: 1.9em
  padding: 0.7em 1em
  border-radius: 35%
  top: 7em

.glider-next
  right: 0.3em
.glider-prev
  left: 0.3em

.slide
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  gap: 2em
  padding: 1em

  > .cover
    font-size: 2.0em
  > .title-card
    height: 20%

  & a.cover-link
    display: block

  & > div
    display: block
    margin-left: auto
    margin-right: auto

.slide
  font-size: 1em
  transition: font-size 500ms
.slide.center
  font-size: 1.6em

</style>
