<template lang="pug">
.screen-tab
  router-link(:to="path")
    .arrow(style="text-align:center; line-height: 1") ⌃
    slot
</template>

<script>
export default {
  props: {
    path: String,
    image: String,
  },
}
</script>

<style lang="sass">
.screen-tab
  display: block

.screen-tab .text
  text-transform: uppercase
  font-size: 0.85em
  letter-spacing: 0.6pt
  margin: 0.2em 0

.screen-tab .icon
  font-size: 2.3em
  margin: 0.2em 0 0.1em

.screen-tab
  .arrow
    margin-top: 0.2em

.screen-tab
  a
    opacity: 0.5
    display: inline-block
    text-align: center

    .arrow
      visibility: hidden

  a:not([href="#/"]).router-link-active, a.router-link-exact-active
    opacity: 1

    .arrow
      visibility: visible

</style>
