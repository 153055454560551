<template lang="pug">
#screen.screen
  header#header
    #status-bar.level
      .branding.title
        slot(name="branding")

      .clock: text-clock


  main#main.columns
    div.left#pane
      section#breadcrumbs Livres numériques

      slot

      section#tabs: .tabs
        screen-tab.home(path="/")
          .text En vedette
          .icon: Icon(icon="ant-design:star-filled")

        screen-tab(path="/sujets")
          .text Sujets
          .icon: Icon(icon="icon-park-outline:circles-seven" :style="{fontSize: '0.9em'}")

        screen-tab(path="/services")
          .text Services
          .icon: Icon(icon="fluent:lightbulb-filament-20-filled")

    .right

  footer#footer
    div
      p.small Propulsé par Manivelle
</template>

<script>
import ScreenTab from "/src/components/ScreenTab.vue";
import TextClock from "/src/components/TextClock.vue";
import { Icon } from '@iconify/vue2';

export default {
  name: "AppScreen",

  components: {
    Icon,
    ScreenTab,
    TextClock,
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    goHome(){
      // Will reject if already at '/'
      this.$router.push("/").catch(e=>e)
    }
  }
};
</script>

<style lang="scss">

@mixin backdrop-blur($size) {
  -webkit-backdrop-filter: blur($size);
  backdrop-filter: blur($size);
}


h2 {
  color: white;
  font-weight: 500;
  font-size: 2em;
  margin: 0 0 0.3em;
}

a,
a:visited {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.columns { display: flex; flex-direction: row; }

.level {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



//- Full height
html { height: -webkit-fill-available; }
html, body { margin: 0; }

body, .screen {
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  overflow: hidden;
}

//- Font size

// height:
// ..959: 12px
// ...1920: 12.8px
// 1920..: 18px
// 2160..: 32px
@media (max-width: 959px) {
  html { font-size: 12px; }
}

@media (max-height: 1919px) {
  html { font-size: 12.8px; }
}

// 1080x1920
@media (min-height: 1920px) {
  html { font-size: 18px; }
}

@media (min-height: 2160px) {
  html { font-size: 32px; }
  .tabs { font-size: 1.4em; }
}

//- Screen layout
.screen {
  &.something {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * { width: 100%; }
  }

  #view { width: 100%; }

  // > header { flex-basis: 12%; }
  // > header #status-bar { width: 100%; }
}

// Needs to shrink within full-height flex container
#main { flex: 1 1 auto; min-height: 0; }

#main > .right {
  background: #111;
  flex: 1 0 35em;
}

#pane, .pane {
  display: flex; flex-direction: column; justify-content: space-between;
  width: 100%; min-height: 0;
  min-width: 0;
}



#view { padding: 2em; }

#view.full {
  justify-content: center; display: flex; flex-direction: column;
  padding: 0;
}


#view { flex-grow: 1; }


#breadcrumbs { padding: 1em; }
#footer { padding: 0.5em 2em; }

#breadcrumbs { font-size: 1.1em }
#footer { font-size: 0.8em; }

// Screen: Base theme
.screen { color: white; }
#header { color: white; }

#status-bar { background: var(--status-bar-color); }
#footer { background: var(--footer-color, #111111); color: #ffffff66; }


#breadcrumbs { background: var(--breadcrumbs-color, #142B40); }
#tabs { background: var(--tabs-color, #00000066); }


// Themes

.theme-bsi {
  #main { background: rgba(0, 0, 0, 0) linear-gradient(rgb(162, 45, 73) 0%, rgb(86, 24, 20) 100%) repeat scroll 0% 0%; }
  --status-bar-color: #D82136;
  --breadcrumbs-color: #142B40;
  --tabs-color: hsl(208, 50%, 20%);

  .button,
  .primary.button {
    background: hsl(208, 60%, 35%);
    border: 1px solid hsl(208, 60%, 20%);
  }
}

.theme-2 {
  #header {
    background: rgba(255, 255, 255, 0.1);
    @include backdrop-blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  #footer {
    background: #2A374Bcc;
    color: white;
    @include backdrop-blur(10px);

    border-radius: 9px 9px 0px 0px;

    a { color: #ffffff99; }
  }

  // .screen {
  //   background-size: cover;
  //   background-position: center;
  // }
}

.theme-banq {
  #main { background: hsl(16, 80%, 30%); }
  #main { background: linear-gradient(to bottom, #e3833c, #550f0f); }

  --status-bar-color: hsl(209, 90%, 33%);
  --breadcrumbs-color: hsl(16, 82%, 45%);
  // --tabs-color: hsl(16, 40%, 15%);

  #status-bar {
    border-bottom: 0.1em solid hsl(209, 20%, 25%);
  }
}

#header {
  > * { padding: 1em; }
  #status-bar { font-size: 1.4em; }

  .title {
    font-size: 0.7em;
    line-height: 1.2;
  }

  .title :is(b, strong) {
    display: block;
    font-size: 1.5em;
    font-weight: 600;
  }

  .clock {
    display: flex;
    align-items: center;
    font-size: 2em;
    font-weight: 600;
  }
}


.items.horizontal {
  display: flex;
  overflow: scroll;
  gap: 0.8em;
}

.items .item {
  border-radius: 5px;
  flex: none;
}



.section1 .card {
  width: 9em;
  height: 13em;
}


.tabs{ display: flex; justify-content: space-around; }

#tabs {
  font-size: 1.2em;
  @media (max-aspect-ratio: 1/1) { font-size: 1.4em; }

  padding: 0.6em;

  img { height: 4em; }

  .tab { padding-top: 0.4em; }

  .disabled { cursor: not-allowed; }
}



.title-card {
  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.3em;
  }

  .authors {
    font-size: 0.85em;
    margin-top: 0.5em;
  }
}

</style>
