import VueRouter from "vue-router";

import Home from "/src/views/Home.vue";
import OpBooksAll from "/src/views/OpBooksAll.vue";
import ViewBookShow from "/src/views/ViewBookShow.vue";
import ViewCategoryItem from "/src/views/ViewCategoryItem.vue";
import ViewCategories from "/src/views/ViewCategories.vue";
import ViewServiceShow from "/src/views/ViewServiceShow.vue";
import ViewServices from "/src/views/ViewServices.vue";


let router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "/",
      component: Home
    },
    {
      name: "BookShow",
      path: "/books/:id",
      component: ViewBookShow,
      props: (route) => ({id: route.params.id})
    },
    {
      path: "/op/books",
      component: OpBooksAll
    },
    {
      name: "Categories",
      path: "/sujets",
      component: ViewCategories,
      props(){
        return {colorHue: 222}
      }
    },
    {
      name: "topic",
      path: "/sujets/:id",
      component: ViewCategoryItem,
      props: (route) => ({id: route.params.id})
    },
    {
      name: "Services",
      path: "/services",
      component: ViewServices,
    },
    {
      name: "ServiceShow",
      path: "/services/:id",
      component: ViewServiceShow,
      props: (route) => ({id: route.params.id}),
    }
  ]
});

export default router;
