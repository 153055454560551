<template lang="pug">
.service.show.view.container
  nav-back

  article.service.item
    .image.column
      figure.image
        img(:src="image" width="400")
        caption {{image_credit}}

    .right.content
      header.headers
        h1 {{title}}
        h2 {{subtitle}}

      .description(v-html="body")
</template>

<script>
import services from "../misc/services-banq.js";

export default {
  name: "ViewServiceShow",
  props: {id: String},

  data(){
    let service = services.filter(s => s.slug == this.id)[0]

    return {
      title: service.title,
      subtitle: service.subtitle,
      body: service.body,
      image: service.image,
      image_credit: service.image_credit,
    }
  },
}
</script>

<style lang="scss">
article.service.item header {
  font-size: 1.6em;

  h1 { font-size: 1.3em; }
  h2 { font-size: 1.0em; }

  h1, h2 { margin: 0; }
  h1 { margin-bottom: 0.16em; }

  margin-bottom: 1em;
}

article.service.item figure {
  margin: 0 0 1.6em;
  img { width: 100%; }
}

figure caption { width: 100%; text-align: right; color: #fff6; font-size: 0.9em; }
</style>
