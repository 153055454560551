<template lang="pug">
app-screen.screen-bsi.theme-bsi
  template(v-slot:branding="")
    strong Bibliothèque des sciences et de l'industrie
    | Rez-de-chaussée

  slot
</template>

<script>
import AppScreen from "./AppScreen.vue";
import fetchJson from "../lib/fetchJson.js";
import _ from "lodash";


export default {
  components: {
    AppScreen,
  },

  mounted(){
    this.loadData()
  },

  methods: {
    async loadBooks(url){
      let {books} = await fetchJson(url)

      books = books.map(b => this.loadBook(b))

      this.$emit("update:books", books);
    },

    loadBook(original){
      let { identifier, metadata } = original;

      if (metadata.image && metadata.image.match(/.jpg$/)) {
        metadata.cover = metadata.image.replace(/^(http:)/, "https:");
      }

      let authors = original.metadata.authors || []

      let authorNames = authors.map(
        ({firstName, lastName}) => [firstName, lastName].join(" ")
      )

      return ({ identifier, original, authorNames, ...metadata });
    },

    async loadTopics(){
      let topicsUrl = "https://manivelle-cdn-1.nyc3.digitaloceanspaces.com/bsi/2021-08-19-category-index.json"
      let {categoryIndex} = await fetchJson(topicsUrl, {camelize: false})

      categoryIndex = _.map(categoryIndex, (v, k) => ({
        label: k,
        identifier: _.kebabCase(k),
        bookIds: v,
        size: v.length,
      }));

      this.$emit("update:topics", categoryIndex)
    },

    async loadData(){
      let books = this.loadBooks("https://manivelle-cdn-1.nyc3.digitaloceanspaces.com/bsi/2021-08-19-books.json")
      let topics = this.loadTopics();

      await books;
      await topics;

      this.$emit("loaded")
    }
  }
};
</script>

<style lang="sass">
</style>
