<template lang="pug">
#app(@contextmenu="onRightClick" @pointerdown="refreshSession()")
  slot

  screen-banq(
    v-bind:books.sync="books"
    v-bind:topics.sync="topics"
    :overlayActive.sync="overlayActive"
    @loaded="loaded = true"
  )
    router-view#view.scroll(v-if="loaded" :autoscroll="false")

    session-tracker(
      ref="sessionTracker"
      @expire="activateOverlay"
      :status.sync="userStatus"
      :delay="30"
    )
</template>

<script>
import router from "./lib/router.js";

import DataSource from "./components/DataSource.vue";
import SessionTracker from "./components/SessionTracker.vue";

import ScreenBanq from "./screens/ScreenBanq.vue";
import ScreenBsi from "./screens/ScreenBsi.vue";

import {keyBy} from "lodash";


export default {
  name: "App",
  router,

  components: {
    DataSource,
    ScreenBanq,
    ScreenBsi,
    SessionTracker,
  },

  data() {
    return {
      books: [],
      topics: [],

      loaded: false,
      userStatus: null,
      overlayActive: false,
    };
  },

  computed: {
    topicsById(){
      return keyBy(this.topics, "identifier");
    },

    booksById(){
      return keyBy(this.books, "identifier");
    },

    slideshowBooks() {
      let coll = this.books;
      coll = coll.filter((r) => r.cover)

      if (!coll.length) return [];

      let n = 30;

      let m = coll.length - n;
      let i = Math.floor(Math.random() * m);
      return coll.slice(i, i + 30);
    },

    autoscrollActive(){
      return (this.userStatus == "absent")
    },
  },

  methods: {
    onRightClick(e){
      if(e.buttons == 3){ return; }
      if(e.shiftKey){ return; }
      e.preventDefault();
    },

    refreshSession(){
      let tracker = this.$refs.sessionTracker;
      return tracker?.touch();
    },

    activateOverlay(){
      this.overlayActive = true
    },
  }
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

//-  Font
body { font-family: Work Sans, sans-serif; }
p { line-height: 1.4; }

.flex { display: flex; }
.horizontal.grid { display: grid; grid-auto-flow: column; }

.float-left {
  float: left;
  margin-right: 1em;
}

table {
  td {
    padding: 0.1em 0.5em 0.1em 0em;
  }
}

.image.is-square {
  position: relative;
  padding-top: 100%;
  height: 0;

  img {
    position: absolute;
    bottom: 0; left: 0; right: 0; top: 0;
    height: 100%;
    object-fit: cover;
  }
}

img { max-width: 100%; height: auto; }

.button {
  font-size: 1.1em;
  padding: 0.4em 1.2em;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  background: hsl(210, 20%, 50%);
  color: white;
}

.section {
  margin: 1em 0;
}

.container { max-width: 40rem; margin: auto; }

// Especially on Windows
.scroll { scrollbar-width: none; overflow-y: auto;}
.scroll::-webkit-scrollbar { display: none; }
</style>
