<template lang="pug">
.services.view
  .services.list.horizontal.grid
    router-link.service(:to="{name: 'ServiceShow', params: {id: service.slug}}" v-for="service in services")
      figure: img(:src="service.image")
      .right
        h2 {{service.title}}
        h3 {{service.subtitle}}
</template>

<script>
import services from "../misc/services-banq.js";

export default {
  name: "ViewServices",

  data(){
    return {services}
  },
}
</script>

<style lang="scss" scoped>
#view.services { margin: 0; padding: 4em; }
#view.services { display: flex; }

.services.grid {
  grid-template-rows: repeat(auto-fit, minmax(8em, 1fr));
  grid-auto-columns: 28.7em;
  gap: 1.6em;
}

.services.view.scroll { overflow-x: auto; }


.service { display: flex; gap: 1.6em; align-items: center; }

.service {
  padding: 1.2em;
  border-radius: 0.2em;
  overflow: hidden;
  background: #00000044;
  box-shadow: 0.1em 0.15em 0.25em #00000099;
}

.service h2 { font-size: 1.6em; color: inherit; }
.service h3 { margin: 0; font-weight: normal; }

.service > figure {
  flex: 0 0 8em;
  margin: 0; max-height: 7em; overflow: hidden;
  display: inline-flex;
  border-radius: 0.3em;

  img {
    box-shadow: 0 0 0.4em #00000033 inset;
  }
}

.caption { font-size: 0.8em; opacity: 0.7; }
</style>
