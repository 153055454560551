<template lang="pug">
nav.back
  a.back(href="#", @click.prevent="$router.go(-1)") &larr; Retour
</template>

<script>
export default {
};
</script>

<style lang="scss">
nav.back { font-size: 0.9em; margin-bottom: 2.0rem; }

a.back {
  padding: 0.4em 0.8em; border-radius: 0.3em;
  border: 1px solid #ffffff66;
}
</style>
