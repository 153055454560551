<template lang="pug">
.book-view
  nav-back.container
  book-record.container(v-bind="record")
</template>

<script>
import BookRecord from "/src/components/BookRecord.vue";

export default {
  components: { BookRecord },

  props: { id: String },

  computed: {
    record() {
      return this.$router.app.booksById[this.id];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
