<template lang="pug">
.screen-showcase(@click="$emit('touch')")
  section.content.box

    //- article.service.item.flex
    .left.column
      figure.image
        img(:src="service.image" width="400")
        caption {{service.image_credit}}

    .right.content
      header.headers
        h1 {{service.title}}
        h2 {{service.subtitle}}

      .description(v-html="service.body")
</template>

<script>
import services from "../misc/services-banq.js";
import {random} from "lodash";

export default {
  props: {
    delay: {type: Number, default: () => 30000}
  },

  data(){
    let cards = [...services];
    let currentIndex = random(cards.length)

    return {
      cards,
      currentIndex,
      intervalID: null,
    }
  },

  mounted(){
    this.intervalID = setInterval(this.onInterval, this.delay);
  },

  beforeDestroy(){
    clearInterval(this.intervalID)
  },

  computed: {
    service(){
      return this.cards[this.currentIndex % this.cards.length]
    },
  },

  methods: {
    onInterval(){
      this.currentIndex++;
    }
  }
}
</script>

<style lang="scss" scoped>
.screen-showcase {
  backdrop-filter: blur(1.2em);
  display: flex; align-items: center; justify-content: center;
  gap: 2em;
  padding: 10em;
}

p { margin-bottom: 0; }
.description p { line-height: 1.6; }

section.box {
  background: #00000099;

  font-size: 1.6em;
  padding: 1.6em 2.0em; border-radius: 0.6em;

  min-height: 16em;

  display: flex; gap: 2.5em; align-items: center;
}

.left.column {
  flex: 0 0 16em;
  img { width: 100%; }
}

.content header {
  font-size: 1.6em;

  h1 { font-size: 1.3em; }
  h2 { font-size: 1.0em; }

  h1, h2 { margin: 0; }
  h1 { margin-bottom: 0.16em; }

  margin-bottom: 1em;
}

figure caption { font-size: 0.8em; }
</style>
