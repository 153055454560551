<template lang="pug">
div.screen.theme-banq.columns
  section.left.column.box.vertical.flex
    .branding.grow
      .logo: img(src="/banq/logo.svg" width="90" :style="{width: '7em'}")
      .text Grande<br>Bibliothèque
    .clock: text-clock

  main.main.column
    screen-showcase.overlay(v-if="overlayActive" @touch="closeOverlay" :delay="10000")

    header.top.box {{screenTitle}}

    slot

    section#tabs.tabs
      screen-tab.home(path="/")
        .text Nouveautés
        .icon: Icon(icon="ant-design:star-filled")

      screen-tab(path="/sujets")
        .text Sujets
        .icon: Icon(icon="icon-park-outline:circles-seven" :style="{fontSize: '0.9em'}")

      screen-tab(path="/services")
        .text Services
        .icon: Icon(icon="fluent:lightbulb-filament-20-filled")

  //- section.right.column.box.shut
  template
</template>

<script>
import AppScreen from "./AppScreen.vue";
import ScreenShowcase from '../components/ScreenShowcase.vue';
import ScreenTab from "/src/components/ScreenTab.vue";
import TextClock from "/src/components/TextClock.vue";
import { Icon } from '@iconify/vue2';

import fetchJson from "../lib/fetchJson.js";
import services from "../misc/services-banq.js";

export default {
  components: {
    AppScreen,
    Icon,
    ScreenShowcase,
    ScreenTab,
    TextClock,
  },

  props: {
    overlayActive: {type: Boolean, default: () => false}
  },

  data(){
    return {
      services
    }
  },

  mounted(){
    this.loadData();
  },

  computed: {
    appSection(){
      let path = this.$route.path

      if(path.match(/^\/services/)) return "services"
      return "books"
    },

    screenTitle(){
      return {
        services: "Services et collections",
        books: "Empruntez un livre numérique",
      }[this.appSection]
    }
  },

  methods: {
    async loadBooks(url){
      let {books} = await fetchJson(url)
      this.$emit("update:books", books);
    },

    async loadTopics(url){
      let {classifications} = await fetchJson(url)

      classifications = classifications
        .map(cls => ({...cls, label: cls.captions?.fr, bookIds: cls.bookIdentifiers}))
        .filter(cls => cls.label)

      this.$emit("update:topics", classifications)
    },

    async loadData(){
      let books = this.loadBooks("https://manivelle-cdn-1.nyc3.cdn.digitaloceanspaces.com/banq/banq-2021-09-08-books.json");
      let topics = this.loadTopics("https://manivelle-cdn-1.nyc3.digitaloceanspaces.com/banq/banq-2021-09-08-classifications.json");

      await books;
      await topics;

      this.$emit("loaded")
    },

    closeOverlay(){
      this.$emit("update:overlayActive", false)
    },
  }
};
</script>

<style lang="scss" scoped>

.box { padding: 1.5em; }
.block { margin: 1em 0; }

.vertical.flex { display: flex; flex-direction: column; }
.grow { flex-grow: 1; }


$main-bg: hsl(216, 16%, 13%);

.left.column {
  $bleu: hsl(209, 90%, 33%);
  $lightened: hsl(209, 84% ,44%);

  background: $bleu;
  border-right: 0.6em solid $lightened;
}

#tabs { border-top: 0.2em solid hsl(217,20%,15%); }

.left.column {
  flex: 0 0 24em;
}

.main.column{
  min-width: 30em;
  flex: 1 1 auto;
  background: $main-bg;
  border-right: 0.8em solid darken($main-bg, 4%);
}

.right.column {
  // $color: hsl(222, 25%, 80%);
  $color: darken($main-bg, 10%);
  background: $color;
  // border-left: 0.7em solid lighten($color, 2%);
  flex: 0 0 20em;
}

.shut {
  width: 0;
  padding: 0;
  flex-basis: 0 !important;
}

.main > .top {
  font-size: 1.6em;
  padding: 1.0em 1.2em;

  $rouge: hsl(11, 75%, 48%);
  background: $rouge;
  // border-top: 0.2em solid darken($rouge, 6%);
  border-bottom: 0.2em solid darken($rouge, 10%);
}

.main.column {  // as container
  display: flex; flex-direction: column; // vertical flex

  > .content { flex-grow: 1; }
}

.main.column {
  position: relative;

  > .overlay { position: absolute; width: 100%; height: 100%; z-index: 1; }
  > * { z-index: 0; }
}


.branding { font-size: 1.6em; line-height: 1.5;  }
.branding > * { margin-bottom: 0.5em; }
.clock { font-size: 4.3em; font-weight: 500; }
</style>
