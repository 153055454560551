export default [
  {
    subtitle: "Romans@lire",
    title: "",
    image: "/banq/media/image8.jpg",
    slug: "romansalire",
    image_credit: "Sebastien Wiertz",
    body: `
    <p>Que lirez-vous maintenant? Demandez à <strong>Romans@lire</strong>! Cette plateforme recense près de 50 000 romans écrits ou traduits en français. La recherche peut se faire notamment par prix littéraire, par genre, par personnage principal ou par lieu du récit. <strong>numerique.banq.qc.ca</strong></p>
    `
  }, {
    subtitle: "R@conte-moi une histoire",
    title: "",
    image: "/banq/media/image4.jpg",
    slug: "raconte-moi",
    body: `
    <p>Vous n’arrivez pas à satisfaire votre enfant qui demande sans cesse une nouvelle histoire? Vous trouverez ici plus de 300 histoires en différentes langues d’une durée de quelques minutes qu’il pourra écouter en ligne. <strong>jeunes.banq.qc.ca</strong></p>
    `
  }, {
    subtitle: "CAP",
    title: "",
    slug: "cap",
    image: "/banq/media/image1.jpg",
    image_credit: "Jose Luis Pelaez Inc / Blend / Learning Pictures / Universal Images Group",
    body: `
    <p>Vous savez ce que vous voulez, mais pas où le trouver? Utilisez les postes d’accès au catalogue pour localiser le document que vous cherchez. Il y en a à chaque étage.</p>
    `
  },{
    subtitle: "BAnQ chez moi",
    title: "",
    slug: "banq-chez-moi",
    image: "/banq/media/image5.jpg",
    image_credit: "Terry Vine / Blend / Learning Pictures / Universal Images Group",
    body: `
    <p>Abonnez-vous au blogue <em>BAnQ chez moi</em> pour être informé de tout ce que BAnQ vous permet de faire sans vous déplacer. <strong>blogues.banq.qc.ca/chezmoi</strong></p>
    `
  }, {
    subtitle: "Commentaires",
    title: "",
    slug: "commentaires",
    image: "/banq/media/image6.jpg",
    image_credit: "Suzanne Szasz / Photo Researchers / Universal Images Group",
    body: `
    <p>Faites-nous part de vos commentaires et de vos suggestions en ligne à <strong>banq.qc.ca/commentaires</strong>.</p>
    `
  }, {
    subtitle: "Réservation de documents",
    title: "",
    slug: "reservation-documents",
    image: "/banq/media/image3.jpg",
    image_credit: "Andersen Ross / Blend / Learning Pictures / Universal Images Group",
    body: `
    <p>Le document que vous convoitez est déjà emprunté par une autre personne? Réservez-le à partir du catalogue en ligne! Nous vous enverrons un avis par courriel lorsqu’il sera disponible pour vous. Assurez-vous que votre adresse de courriel est bien inscrite dans votre dossier d’abonné.</p>
    `
  }, {
    subtitle: "Romans",
    title: "",
    slug: "romans",
    image: "/banq/media/image2.jpg",
    image_credit: "Joti / Science Photo Library / Universal Images Group",
    body: `
    <p>Policiers, fantastiques, sentimentaux ou historiques. Québécois ou étrangers. En français ou en anglais. Des romans pour tous les goûts abondent au niveau 1. Flânez dans les allées, laissez-vous tenter par des auteurs que vous n’avez jamais fréquentés ou repérez le titre que vous souhaitez lire depuis toujours. Vous préférez le numérique? Rendez-vous à <strong>banq.qc.ca/livresnumeriques</strong> pour y télécharger votre prochain coup de cœur. En manque d’inspiration? Demandez conseils aux bibliothécaires du niveau 1.</p>
    `
  }, {
    subtitle: "Disques compacts",
    title: "",
    slug: "disques",
    image: "/banq/media/image7.jpg",
    image_credit: "Jason Edwards / National Geographic Society / Universal Images Group",
    body: `
    <p>Curieux et amateur de musique? Mille et une découvertes vous attendent au <strong>niveau&nbsp;4</strong> (au <strong>niveau&nbsp;M</strong> pour les 13 ans et moins). Empruntez jusqu’à 10 CD de musique du monde, de musique populaire, de jazz, de musique classique, de musique de films… Et ce n’est pas tout! Vous pouvez aussi vous évader en musique simplement en accédant à <strong>numerique.banq.qc.ca</strong>.</p>
    `
  }
]
