<template lang="pug">
.book.list.view.container
  nav-back

  h1 {{ title }}

  .book.list
    .book.item(v-for="book in books")
      book-list-item(v-bind="book")
</template>

<script>
import {compact} from "lodash";

export default {
  name: "ViewCategoryItem",
  props: ["id"],

  computed: {
    topic() {
      return this.$router.app.topicsById[this.id] || {};
    },

    books() {
      let { bookIds } = this.topic;
      let books = bookIds.map(id => this.$router.app.booksById[id])
      return compact(books)
    },

    title() {
      return this.topic.label;
    },
  },
};
</script>
