import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import App from "./App.vue";
import _ from "lodash";

import BookCover from "/src/components/BookCover.vue";
import BookListItem from "/src/components/BookListItem.vue";
import NavBack from "./components/NavBack.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

window._ = _;

Object.assign(Vue.prototype, {
  bookPath(i) {
    return `/books/${i}`;
  }
});

Vue.component("book-cover", BookCover);
Vue.component("book-list-item", BookListItem);
Vue.component("nav-back", NavBack);

// createApp(App).mount("#app");
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "_store", {
  get() {
    return this.$root.store;
  }
});

const store = new Vuex.Store({
  state: {
    slideshowScroll: null
  },

  mutations: {
    setSlideshowScroll(state, val) {
      state.slideshowScroll = val;
    }
  }
});

new Vue({
  render: (h) => h(App),
  store,

  computed: {
    app() {
      return this.$children[0];
    }
  }
}).$mount("#app");
